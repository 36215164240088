import {HTTP} from '../utils/axios'

class ReplyModel extends HTTP {
    addReply(data) {
        return this.request({
            url: '/api/v1/reply',
            method: "POST",
            data: data
        })
    }
}

export { ReplyModel}