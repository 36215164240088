<template>
    <div>
        <div class="header">
            项目管理
        </div>
        <div class="message">
            <div class="search">
                <a-form layout="inline" :form="form" @submit="handleSearch">
                    <a-form-item>
                        <a-input
                        v-decorator="[
                                'comment'
                            ]"
                        placeholder="请输入留言内容关键字"
                        autocomplete="off"
                        ></a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-select
                            style="width: 150px;"
                            v-decorator="[
                                'community_id'
                                ]"
                            placeholder="请选择社区"
                            showSearch
                            option-filter-prop="children"
                            :filter-option="filterOption"
                            >
                            <a-select-option
                                v-for="(item, index) in communities"
                                :key="index"
                                :value="item.id"
                            >{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item>
                        <a-select
                            style="width: 150px;"
                            v-decorator="[
                                'is_checked'
                                ]"
                            placeholder="请选择审核状态"
                            showSearch
                            option-filter-prop="children"
                            :filter-option="filterOption"
                            >
                            <a-select-option value="1">待审核</a-select-option>
                            <a-select-option value="2">通过</a-select-option>
                            <a-select-option value="3">未通过</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item>
                        <a-button html-type="submit">搜索</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="handleRefesh" html-type="button">刷新</a-button>
                    </a-form-item>
                </a-form>
            </div>
            <a-table
                bordered
                :columns="columns"
                :dataSource="data"
                :pagination="false"
                :loading="loading"
                :scroll="{ x: true}"
            >
                <template slot="comment" slot-scope="text, record">
                    <span v-if="record.comment" class="link" @click="handleModal(record,'留言内容')">{{record.comment}}</span>
                    <span v-else>暂无留言</span>
                </template>
                <template slot="photo" slot-scope="text, record">
                    <span v-if="JSON.parse(record.photo).length > 0" class="link" @click="handleModal(record, '照片')">有照片</span>
                    <span v-else>无照片</span>
                </template>
                <template slot="apply3" slot-scope="text, record">
                    <span v-if="record.items" class="link" @click="handleModal(record, '回复内容')">{{record.items ? record.items[0].content : ' '}}</span>
                    <span v-else >暂无回复</span>
                </template>
                <span slot="action" slot-scope="text, record">
                    <a href="javascript:;" @click="handleCommity(record, '审核')">审核</a>
                    <a href="javascript:;" @click="handleCommity(record, '回复')">回复</a>
                    <a href="javascript:;" class="delete" @click="handleDelete(record)">删除</a>
                </span>
            </a-table>
            <Pagination :current="current" :total="total" @handleChange="handleChange" v-show="total > 10" />
            <a-modal
                :title="title"
                class="modal"
                v-model="visible"
                width="450px"
                :footer="null"
                >
                <div v-if="flag1">
                    <a-comment>
                        <a slot="author">{{record.created_at}}</a>
                        <p slot="content">
                            {{record.comment}}
                        </p>
                    </a-comment>
                </div>
                <div class="img" v-if="flag2">
                    <div v-for="(item, index) in JSON.parse(record.photo)" :key="index">
                        <img :src="item" alt="">
                    </div>
                </div>
                <div v-if="flag3">
                    <a-comment v-for="(item, index) in record.items" :key="index">
                        <a slot="author">{{item.created_at}}</a>
                        <p slot="content">
                            {{item.content}}
                        </p>
                    </a-comment>
                </div>
                <div class="btn">
                    <a-button style="display: none;" class="ant-btn ant-btn-primary" @click="handleModalCancel">关闭</a-button>
                    <a-button class="ant-btn ant-btn-primary" @click="handleModalCancel">关闭</a-button>
                </div>
            </a-modal>
            <a-modal
                :title="title"
                class="modal"
                v-model="visible1"
                width="450px"
                @ok="handleOk"
                @cancel="handleCancel"
                okText="确认"
                cancelText="取消"
                >
                <a-form :form="form1" :label-col="{ span: 4}" :wrapper-col="{ span: 20 }">
                    <a-form-item v-if="title == '审核'" label="审核结果">
                        <a-radio-group v-decorator="['is_checked']">
                            <a-radio value="2">
                                通过
                            </a-radio>
                            <a-radio value="3">
                                未通过
                            </a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item v-else label="回复内容">
                        <a-textarea
                            v-decorator="['content']"
                            style="resize: none;"
                            :rows="6"
                        />
                    </a-form-item>
                </a-form>
            </a-modal>
        </div>
    </div>
</template>

<script>
import Pagination from "./../../components/Pagination.vue";
import { UserModel } from "./../../model/user";
import formatTime from './../../utils/formatTime'
import { ManageModel } from "../../model/manage";
import { ReplyModel } from "../../model/reply";
const manageModel = new ManageModel();
const userModel = new UserModel();
const replyModel = new ReplyModel();
const isChecked = ['待审核', '通过', '未通过'];
const communityArr = ['新华', '西洋桥', '大南', '公园', '新园', '碧林湾',
            '闸东', '永泰', '高店', '尖沟头', '怡园', '横河', '长岸', '花墙'];
const columns = [
  {
    title: "用户微信名称",
    dataIndex: "wx_name",
    key: "wx_name"
  },
  {
    title: "用户姓名",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "联系方式",
    dataIndex: "phone",
    key: "phone"
  },
  {
    title: "居住地址",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "留言内容",
    dataIndex: "comment",
    key: "comment",
    scopedSlots: { customRender: "comment" }
  },
  {
    title: "照片",
    dataIndex: "photo",
    key: "photo",
    scopedSlots: { customRender: "photo" }
  },
  {
    title: "回复内容",
    dataIndex: "apply3",
    key: "apply3",
    scopedSlots: { customRender: "apply3" }
  },

  {
    title: "发布社区",
    dataIndex: "community_id",
    key: "community_id",
    customRender: function(text) {
        return communityArr[Number(text) - 1] + '社区'
    }
  },

  {
    title: "审核状态",
    dataIndex: "is_checked",
    key: "is_checked",
    customRender: function(text) {
        return isChecked[Number(text) - 1]
    }
  },

  {
    title: "留言时间",
    dataIndex: "created_at",
    key: "created_at",
    customRender: function(text) {
        return formatTime(text)
    }
  },
  
  {
    title: "操作",
    dataIndex: "",
    width: 250,
    key: "action",
    scopedSlots: {
      customRender: "action"
    }
  }
];

const data = [] 
export default {
    data() {
        return {
            form: this.$form.createForm(this, {
                name: "horizontal_login"
            }),
            form1: this.$form.createForm(this, {
                name: "horizontal_login"
            }),
            columns,
            data,
            total: 0,
            current: 1,
            id: 0,
            comment: '',
            communityId: 0,
            isChecked: 0,
            visible: false,
            visible1: false,
            loading: true,
            flag1: false,
            flag2: false,
            flag3: false,
            title: '',
            communities: [],
            record: {}
        }
    },

    mounted() {
        this._queryCommunities()
        this._queryUsers({comment: this.comment, community_id: this.communityId, is_checked: this.isChecked, page: 1})
    },

    methods: {
        handleRefesh() {
            this.current = 1
            this.communityId = 0
            this.name = ''
            this._queryUsers({comment: '', community_id: 0, is_checked: 0, page: 1})
            this.form.resetFields()
        },

        handleModal(record, text) {
            this.visible = true
            this.title = text
            if(text == '留言内容') {
                this.flag1 = true
                this.flag2 = this.flag3 = false
            }

            if(text == '回复内容') {
                this.flag3 = true
                this.flag1 = this.flag2 = false
            }

            if(text == '照片') {
                this.flag2 = true
                this.flag3 = this.flag1 = false
            }
            record.created_at = formatTime(record.created_at)
            if(record.items) {
                record.items.map(item => {
                    item.created_at = formatTime(item.created_at)
                    return item
                })
            }
            this.record = record
        },

        handleModalCancel() {
            this.visible = false
        },

        handleCommity(record, text) {
            if(text == '回复') {
                if(!record.comment) {
                    this.$notification.warning({
                        message: "提示",
                        description: '当前用户没留言，管理员不能回复！',
                        duration: 2
                    })
                    return
                }
            } 
            this.visible1 = true
            this.title = text
            this.record = record
        },

        handleCancel() {
             this.visible = false
             this.form1.resetFields()
        },

        handleSearch (e) {
            e.preventDefault()
            this.form.validateFields((err, values) => {
                if (!err) {
                    values.page = this.current = 1
                    this.comment = values.comment
                    this.communityId = values.community_id
                    this.isChecked = values.is_checked
                    this._queryUsers(values)
                }
            })
        },

        handleOk(e) {
            e.preventDefault()
            this.form1.validateFields((err, values) => {
                if (!err) {
                    console.log(values)
                    if(this.title == '回复') {
                        values.user_id = this.record.id
                        replyModel.addReply(values).then(res => {
                            if(res.code == '000000') {
                                this.$notification.success({
                                    message: "提示",
                                    description: res.message,
                                    duration: 2
                                })
                                this.visible1 = false
                                this.form1.resetFields()
                                this._queryUsers({comment: this.comment, community_id: this.communityId, is_checked: this.isChecked, page: this.current})
                            } else {
                                this.$notification.error({
                                    message: "提示",
                                    description: res.message,
                                    duration: 2
                                })
                            }
                        })
                    } else {
                        values.id = this.record.id
                        userModel.updateUser(values).then(res => {
                            if(res.code == '000000') {
                                this.$notification.success({
                                    message: "提示",
                                    description: res.message,
                                    duration: 2
                                })
                                this.visible1 = false
                                this.form1.resetFields()
                                this._queryUsers({comment: this.comment, community_id: this.communityId, is_checked: this.isChecked, page: this.current})
                            } else {
                                this.$notification.error({
                                    message: "提示",
                                    description: res.message,
                                    duration: 2
                                })
                            }
                        })
                    }
                }
            })
        },

        handleChange(page) {
            this.current = page
            this._queryUsers({comment: this.comment, community_id: this.communityId, is_checked: this.isChecked, page: this.current})
        },

        handleDelete(record) {
            console.log(record)
            const confirmText = ["确定删除活动吗？"];
            const newDatas = [];
            const h = this.$createElement;
            for (const i in confirmText) {
                newDatas.push(h("p", null, confirmText[i]));
            }
            this.$confirm({
                content: h("div", null, newDatas),
                okText: "确认",
                class: "deleteBtn",
                cancelText: "取消",
                onOk: () => {
                    userModel.deleteUser({id: record.id}).then(res => {
                        if(res.code == '000000') {
                            this.$notification.success({
                                message: "提示",
                                description: res.message,
                                duration: 2
                            })
                            this._queryUsers({comment: '', community_id: 0, is_checked: 0, page: 1})
                        } else {
                            this.$notification.error({
                                message: "提示",
                                description: res.message,
                                duration: 2
                            });
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                },
                onCancel() {}
            });
        },

        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },

        _queryCommunities() {
            manageModel.getCommunities().then(res => {
                if(res.code == '000000') {
                    this.communities = res.data
                } else {
                    this.$notification.success({
                        message: "提示",
                        description: res.message,
                        duration: 2
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        },

        _queryUsers(params) {
            console.log(params)
            this.loading = true
            userModel.index(params).then(res => {
                if(res.code == '000000') {
                    res.data.map(item => {
                        item.key = item.id
                        return item
                    })
                    this.data = res.data
                    this.total = res.totalRecord
                } else {
                    this.$notification.error({
                        message: "提示",
                        description: res.message,
                        duration: 2
                    })
                }
                this.loading = false
            }).catch(err => {
                console.log(err)
                this.loading = false
            }) 
        }
    },

    components: {
        Pagination
    }
}

</script>


<style scoped>
    .message {
        padding: 15px;
    }

    .search {
        box-shadow: 0 0  3px 3px #e6c2c2;
    }

    .header {
        font-size: 16px;
    }

    .search, .header {
        padding: 5px 15px;
    }

    .search /deep/ .ant-input {
        width: 250px;
    }

    .search /deep/ .ant-input:focus,
    .search /deep/ .ant-input:hover {
        border-color: #4DD493;
        box-shadow: 0 0 0 2px rgb(77 212 147 / 20%);
    }

    .search /deep/ .ant-btn,
    .modal /deep/ .ant-btn:nth-of-type(2) {
        border-color: #4DD493;
        background-color: #4DD493;
        color: #fff;
    }

    .modal /deep/ .ant-btn:nth-of-type(1):hover,
    .modal /deep/ .ant-btn:nth-of-type(1):focus {
        color: #4DD493 !important;
        border-color: #4DD493 !important;
    }

    .message a {
        color: #4DD493;
        margin-left: 10px;
        margin-right: 10px;
    }

    a.delete {
        color: #FF0000;
    }

    .message /deep/ .ant-table-wrapper {
        margin-top: 20px;
    }

    .message /deep/ .ant-switch-checked {
        background-color: #4DD493;
    }

    .link {
         color: #409EFF;
         cursor: pointer;
    }

    .btn {
        margin-top: 20px;
        text-align: right;
    }

    .img {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .img > div {
        width: 50%;
        text-align: center;
        margin-bottom: 20px;
    }

    .img > div img {
        width: 120px;
        height: 120px;
    }

</style>

<style>
    .deleteBtn .ant-btn:nth-of-type(1):hover,
    .deleteBtn .ant-btn:nth-of-type(1):focus {
        color: #4DD493 !important;
        border-color: #4DD493 !important;
    }

    .ant-comment-inner {
        padding: 0 0 !important;
    }
</style>
