import {HTTP} from '../utils/axios'

class UserModel extends HTTP {
    index(data) {
        return this.request({
            url: '/api/v1/user',
            method: "GET",
            data: data
        })
    }

    updateUser(data) {
        return this.request({
            url: '/api/v1/user',
            method: "PATCH",
            data: data
        })
    }

    deleteUser(data) {
        return this.request({
            url: '/api/v1/user',
            method: "DELETE",
            data: data
        })
    }
}

export { UserModel}